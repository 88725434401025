import React from 'react';

const PathNotFound = () =>
    <div className="py-5">
        <div className="container">
            <h2 className="title">404</h2>
            <p>The path you acess does not exists.</p>
        </div>
    </div>

export default PathNotFound;
